<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">
          Just Eat Importer
        </h6>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-overlay :show="busy" rounded="lg" opacity="0.6" @hidden="onHidden">
            <template v-slot:overlay>
              <div class="d-flex align-items-center">
                <b-spinner small type="grow" variant="secondary" />
                <b-spinner type="grow" variant="dark" />
                <b-spinner small type="grow" variant="secondary" />
                <!-- We add an SR only text for screen readers -->
                <span class="sr-only">Please wait...</span>
              </div>
            </template>
            <p>Using this tool will remove you categories and products!</p>
            <b-input-group size="lg" :aria-hidden="busy ? 'true' : null">
              <b-form-input v-model="value" :disabled="busy" />
              <b-input-group-append>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" ref="button" variant="primary" @click="onClick">
                  Import Menu
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="dataShow">
      <b-row>
        <b-col md="12">
          <b-table responsive="sm" :items="items">
            <template #cell(items)="row">
              <table class="table b-tableP">
                <thead>
                  <th class="w50">name</th>
                  <th class="w50">price</th>
                </thead>
                <tbody>
                  <tr v-for="(item, itemIndex) in row.item.items" :key="row.length + '-' + itemIndex">
                    <td>{{ item.name }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BOverlay,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTable,
  BButton,
  BCardText,
  BSpinner,
} from 'bootstrap-vue';

import Ripple from 'vue-ripple-directive';

import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BInputGroup,
    BCard,
    BCol,
    BRow,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardText,
    BTable,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    value: '',
    busy: false,
    timeout: null,
    dataShow: false,
    items: []
  }),
  beforeDestroy() {
    this.clearTimeout()
  },
  methods: {
    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
    },
    setTimeout(callback) {
      this.clearTimeout()
      this.timeout = setTimeout(() => {
        this.clearTimeout()
        callback()
      }, 5000)
    },
    onHidden() {
      // Return focus to the button
      this.$refs.button.focus()
    },
    async onClick() {
      this.busy = true
      await useJwt.delete_all_categories('all')
        .then(async () => {
          await useJwt.delete_all_products('all')
            .then(async () => {
              useJwt.justeat_importer({
                url: this.value
              })
                .then(async (response) => {

                  this.items = response.data.data;

                  await useJwt
                    .list_categories()
                    .then((response) => {
                      localStorage.setItem(
                        "userCategories",
                        JSON.stringify(response.data.data)
                      );
                    });
                  await useJwt
                    .list_products()
                    .then((response) => {
                      localStorage.setItem(
                        "userProducts",
                        JSON.stringify(response.data.data)
                      );
                    });


                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Import Sucessfull`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `Just Eat Data has been synchronsised with your database.`,
                    },
                  });

                }).catch((e) => {
                  console.log(e)
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Error`,
                      icon: "CropIcon",
                      variant: "danger",
                      text: `Please check Just eat URL you have .`,
                    },
                  });
                });
            })
            .catch((e) => {
              console.log(e)
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CropIcon",
                  variant: "danger",
                  text: `We couldn't import just eat data.`,
                },
              });
            });
        })
        .catch((e) => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't import just eat data.`,
            },
          });
        });


      // Simulate an async request
      this.setTimeout(() => {
        this.busy = false;
        this.dataShow = true;
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
</style>